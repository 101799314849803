import {
  LoadingHandler,
  useAnalytics,
  DisplayObserver
} from '@monetization/hpaip-ui-shared-components'
import {
  Device,
  DeviceClient,
  SubscriptionStateEnum,
  useLazyFetchRequest
} from '@monetization/hpaip-notification-rules-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Content from './components/Content'
import ErrorContent from './components/ErrorContent'
import { getShipmentInfoFromOrders } from './methods'
import { StyledPage } from './styles'
import { findIsCissPrinter, useLazyGetProducts } from '../../api/services'
import useFulfillmentOrders from 'src/hooks/useFulfillmentOrders'
import CommonProps from '../../types/commonProps'

interface ShipmentTrackingPageProps {
  commonProps: CommonProps
  data: any
}

export const ShipmentTrackingPage = ({
  commonProps,
  data
}: ShipmentTrackingPageProps) => {
  const { t, authProvider, stack } = commonProps
  const { events } = useAnalytics()
  const printerData = data?.printerData
  const { ordersState, isEmptyOrError: hasOrdersError } = useFulfillmentOrders(
    authProvider,
    stack,
    printerData?.root
  )

  const shipmentHistoryTableRef = useRef<HTMLDivElement>()
  const [getProducts] = useLazyGetProducts()

  const [isCissPrinter, setIsCissPrinter] = useState<boolean>(false)
  const [isProductLoading, setIsProductLoading] = useState<boolean>(true)
  const deviceClient = useMemo(
    () => new DeviceClient(authProvider, stack),
    [authProvider, stack]
  )

  const uniqueDeviceId = useMemo(
    () => printerData?.printer?.entityDetails?.uniqueDeviceId || null,
    [printerData?.printer]
  )

  const [
    fetchDevice,
    { data: deviceData, error: deviceError, called: deviceCalled }
  ] = useLazyFetchRequest<Device>(
    async () => await deviceClient.getDeviceCacheInfo(uniqueDeviceId)
  )

  useEffect(() => {
    if (!uniqueDeviceId || deviceCalled) return
    fetchDevice()
  }, [deviceCalled, uniqueDeviceId])

  const isDeviceOffline = useMemo(() => {
    if (
      printerData?.printer?.state === SubscriptionStateEnum.PENDING ||
      printerData?.printer?.state === SubscriptionStateEnum.CANCELED ||
      printerData?.printer?.state === SubscriptionStateEnum.CANCELING
    )
      return false

    return deviceData?.status?.connectionState === 'offline'
  }, [deviceData, printerData?.printer])

  const printCardLabel = useMemo(
    () => ({
      black: t('shipment.print-cards.titles.black', 'Black'),
      tricolor: t('shipment.print-cards.titles.tricolor', 'Tri-color'),
      cyan: t('shipment.print-cards.titles.cyan', 'Cyan'),
      magenta: t('shipment.print-cards.titles.magenta', 'Magenta'),
      yellow: t('shipment.print-cards.titles.yellow', 'Yellow'),
      paper: t('shipment.print-cards.titles.paper', 'Paper'),
      printer: t('shipment.print-cards.titles.printer', 'Printer')
    }),
    [t]
  )

  const shipmentCards = useMemo(
    () =>
      getShipmentInfoFromOrders(isDeviceOffline, ordersState, printCardLabel),
    [isDeviceOffline, ordersState, printCardLabel]
  )

  useEffect(() => {
    const printer = printerData?.printer
    if (!printer) return

    const values = printer.product.value
    const { parentProductSku, productSku } = values || {}
    if (!parentProductSku || !productSku) return
    void getProducts({
      variables: { sku: parentProductSku },
      onCompleted: async (data) => {
        /* istanbul ignore next */
        setIsCissPrinter(await findIsCissPrinter(data))
        setIsProductLoading(false)
      },
      onError: () => {
        setIsProductLoading(false)
      }
    })
  }, [getProducts, printerData])

  return (
    <DisplayObserver eventProps={events.ShipmentTrackingDisplayed()}>
      <StyledPage>
        <LoadingHandler
          loading={
            ((!printerData?.called || printerData?.isLoading) &&
              !printerData?.printer) ||
            isProductLoading
          }
          error={printerData?.error || hasOrdersError || !printerData}
          customError={
            <ErrorContent
              isCissPrinter={isCissPrinter}
              commonProps={commonProps}
            />
          }
        >
          <Content
            subscription={printerData}
            shipmentCards={shipmentCards}
            shipmentHistoryTableRef={shipmentHistoryTableRef}
            isCustomError={deviceError !== null}
            isCissPrinter={isCissPrinter}
            commonProps={commonProps}
            printerData={printerData}
            ordersState={ordersState}
            notificationData={data}
          />
        </LoadingHandler>
      </StyledPage>
    </DisplayObserver>
  )
}
