import { useQuery } from '@apollo/client'
import { GET_PRODUCTS } from '@/api/graphql/products'
import { useEffect, useState } from 'react'

export const useProductQuery = (parentProductSku, disabled) => {
  if (disabled) return
  const [productData, setProductData] = useState(null)
  const { loading, error } = useQuery(GET_PRODUCTS, {
    variables: { sku: parentProductSku },
    skip: !parentProductSku,
    onCompleted: (data) => {
      setProductData(data)
    }
  })

  useEffect(() => {
    if (!parentProductSku) {
      setProductData(null)
    }
  }, [parentProductSku])

  if (loading || error) return []

  return productData?.products?.items
}
