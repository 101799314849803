import GetAllPostCancelNotification from '@/components/GetAllPostCancelNotification/GetAllPostCancelNotification'
import GetAllDeliquencyNotification from '@/components/GetAllDeliquencyNotification/GetAllDeliquencyNotification'
import GetAllPrearrivalNotification from '@/components/GetAllPrearrivalNotification/GetAllPrearrivalNotification'
import GetAllInstantInkPaymentNotification from '@/components/GetAllInstantInkPaymentNotification/GetAllInstantInkPaymentNotification'
import GetAllOfflineNotification from '@/components/GetAllOfflineNotification/GetAllOfflineNotification'
import GetAllPaperNotification from '@/components/GetAllPaperNotification/GetAllPaperNotification'
import getAllNotificationsObjects from '@/helpers/getAllNotificationsObjects'
import GetAllReplacementNotification from '@/components/GetAllReplacementNotification/GetAllReplacementNotification'
import isCASSubscription from '@/utils/isCASSubscription'

export const initializeNotificationsObject = (
  activePrinterData,
  launchdarklyFlags,
  productData
) => {
  const allNotificationsObject = getAllNotificationsObjects()

  allNotificationsObject.notificationsList =
    allNotificationsObject.notificationsList || []

  allNotificationsObject.isCASSubscription = isCASSubscription(
    activePrinterData,
    launchdarklyFlags
  )

  if (activePrinterData) allNotificationsObject.printerData = activePrinterData

  if (productData) allNotificationsObject.graphQLproductData = productData

  return allNotificationsObject
}

export const processPostCancelNotification = (
  mfeConfig,
  subscriptionId,
  root,
  printer,
  productData,
  navigation,
  allNotificationsObject,
  isCancellationTriggered,
  pendingData
) => {
  if (mfeConfig.postcancellation) {
    const notification = GetAllPostCancelNotification(
      subscriptionId,
      root?.tenantId,
      printer,
      productData,
      navigation,
      allNotificationsObject,
      isCancellationTriggered,
      pendingData
    )

    if (notification)
      allNotificationsObject.notificationsList.push(notification)
  }
}

export const processDeliquencyNotification = (
  mfeConfig,
  mfe,
  activePrinterData,
  navigateToBillingHandler,
  navigation,
  allNotificationsObject
) => {
  if (mfeConfig.deliquency) {
    const deliquencyInfo = GetAllDeliquencyNotification(
      mfe,
      activePrinterData,
      navigateToBillingHandler,
      navigation,
      allNotificationsObject
    )

    if (deliquencyInfo?.notificationData)
      allNotificationsObject.notificationsList.push(
        deliquencyInfo.notificationData
      )

    if (deliquencyInfo?.deliquencyStatus)
      allNotificationsObject.deliquencyStatus = deliquencyInfo.deliquencyStatus
  }
}

export const processPrearrivalNotification = (
  mfeConfig,
  mfe,
  printer,
  launchdarklyFlags,
  navigation,
  subscriptionId,
  pendingData,
  allNotificationsObject
) => {
  if (mfeConfig.preaarival) {
    const notification = GetAllPrearrivalNotification(
      mfe,
      printer,
      launchdarklyFlags.enableBuyerRemorseCancellation,
      navigation,
      subscriptionId,
      pendingData
    )

    if (notification)
      allNotificationsObject.notificationsList.push(notification)
  }
}

export const processOfflineNotification = (
  mfeConfig,
  mfe,
  printer,
  allNotificationsObject
) => {
  if (mfeConfig.offline) {
    const notification = GetAllOfflineNotification(
      mfe,
      printer,
      allNotificationsObject
    )

    if (notification)
      allNotificationsObject.notificationsList.push(notification)
  }
}

export const processInstantInkNotification = (
  mfeConfig,
  navigateToIIBillingHandler,
  allNotificationsObject
) => {
  if (mfeConfig.instantInk) {
    const notification = GetAllInstantInkPaymentNotification(
      navigateToIIBillingHandler,
      allNotificationsObject
    )

    if (notification)
      allNotificationsObject.notificationsList.push(notification)
  }
}

export const processPaperNotification = (
  mfeConfig,
  subscriptionId,
  navigation,
  allNotificationsObject,
  pendingData
) => {
  if (mfeConfig.paperCancel) {
    const notification = GetAllPaperNotification(
      subscriptionId,
      navigation,
      allNotificationsObject,
      pendingData
    )

    if (notification)
      allNotificationsObject.notificationsList.push(notification)
  }
}

export const processReplacementNotification = (
  mfeConfig,
  launchdarklyFlags,
  printer,
  allNotificationsObject,
  isCancellationTriggered,
  isReplacementTriggered
) => {
  if (mfeConfig.replacement) {
    const notification = GetAllReplacementNotification(
      launchdarklyFlags,
      printer,
      isCancellationTriggered,
      isReplacementTriggered
    )

    if (notification)
      allNotificationsObject.notificationsList.push(notification)
  }
}
