import useGetText from '../../hooks/useGetText/useGetText'
import { SubscriptionStateEnum } from '@/types'
import { NotificationType, NotificationID } from '../../utils/common-utils'
import defaultLanguageJson from '../../assets/locale/en_US.json'

const GetAllReplacementNotification = (
  launchdarklyFlags,
  printer,
  isCancellationTriggered,
  isReplacementTriggered
) => {
  const getText = useGetText('replacementNotification')
  const enableNewReplacementFlow = launchdarklyFlags?.pfEnableNewReplacementFlow

  if (
    printer?.state == SubscriptionStateEnum.CANCELING &&
    enableNewReplacementFlow &&
    !isCancellationTriggered &&
    isReplacementTriggered
  ) {
    return {
      title: getText('deactivating.title', {
        defaultValue:
          defaultLanguageJson.replacementNotification.deactivating.title
      }),
      description: getText('deactivating.description', {
        defaultValue:
          defaultLanguageJson.replacementNotification.deactivating.description
      }),
      showCloseButton: false,
      notificationType: NotificationType.CUSTOM,
      notificationID: NotificationID.REPLACEMENT
    }
  } else {
    return null
  }
}

export default GetAllReplacementNotification
