import { SubscriptionStateEnum } from '@/types'

export const getCancellationInfo = async (
  rootState,
  printer,
  launchdarklyFlags,
  isDeviceShipped,
  isCancellationTriggered,
  isReplacementTriggered
) => {
  const enableBuyerRemorseCancellation =
    launchdarklyFlags?.enableBuyerRemorseCancellation
  const enableNewReplacementFlow = launchdarklyFlags?.pfEnableNewReplacementFlow
  let showCancelPlan: boolean,
    showCancellationStatus: boolean,
    buttonDisable: boolean,
    firstHourCancellation: boolean
  const updateCancellationState = (
    cancelPlan,
    cancellationStatus,
    disableButton,
    firstHour
  ) => {
    showCancelPlan = cancelPlan
    showCancellationStatus = cancellationStatus
    buttonDisable = disableButton
    firstHourCancellation = firstHour
  }
  if (
    enableBuyerRemorseCancellation &&
    rootState === SubscriptionStateEnum.PENDING
  ) {
    updateCancellationState(
      !isCancellationTriggered,
      Boolean(isCancellationTriggered),
      false,
      true
    )
  } else {
    handleEntityState(
      printer,
      enableBuyerRemorseCancellation,
      updateCancellationState,
      isDeviceShipped,
      isCancellationTriggered,
      enableNewReplacementFlow,
      isReplacementTriggered
    )
  }

  return {
    showCancelPlan,
    showCancellationStatus,
    buttonDisable,
    firstHourCancellation
  }
}

const handleEntityState = async (
  printer,
  enableBuyerRemorseCancellation,
  updateCancellationState,
  isDeviceShipped,
  isCancellationTriggered,
  enableNewReplacementFlow,
  isReplacementTriggered
) => {
  switch (printer?.state) {
    case SubscriptionStateEnum.CANCELED:
      updateCancellationState(false, false, false, false)
      break
    case SubscriptionStateEnum.CANCELING:
      if (enableNewReplacementFlow) {
        updateCancellationState(
          !isCancellationTriggered,
          isCancellationTriggered,
          false,
          false
        )
      } else {
        updateCancellationState(false, true, false, false)
      }
      break
    case SubscriptionStateEnum.SUBSCRIBED:
      updateCancellationState(true, false, false, false)
      break
    case SubscriptionStateEnum.SUSPENDED:
      updateCancellationState(
        !isCancellationTriggered,
        Boolean(isCancellationTriggered),
        false,
        false
      )
      break
    case SubscriptionStateEnum.PENDING:
      if (enableBuyerRemorseCancellation) {
        updateCancellationState(
          !isCancellationTriggered,
          Boolean(isCancellationTriggered),
          false,
          false
        )
      } else {
        const cancellationState = isDeviceShipped
          ? [true, false, false, false]
          : [true, false, true, false]
        updateCancellationState(...cancellationState)
      }
      break
    default:
      break
  }
}
