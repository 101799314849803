import {
  PrinterSubscriptionData,
  SubscriptionStateEnum
} from '@monetization/hpaip-notification-rules-react'
import * as React from 'react'
import { FaqCard } from '../../FaqCard'
import { PrintCards } from '../../PrintCards'
import { ShipmentHistory } from '../../ShipmentHistory'
import { SupportText } from '../../SupportText'
import { ShipmentCard } from 'src/types/shipmentCard'
import {
  Container,
  FaqPanel,
  Section,
  Subheader,
  Subtitle,
  NotificationSection
} from '../styles'
import CommonProps from '../../../types/commonProps'
import { RenderControlProvider } from 'src/contexts'
import { MultipleNotification } from '@monetization/hpaip-ui-shared-components'

interface ContentProps {
  subscription: PrinterSubscriptionData
  shipmentCards: ShipmentCard[]
  shipmentHistoryTableRef: React.MutableRefObject<HTMLDivElement>
  commonProps: CommonProps
  isCustomError?: boolean
  isCissPrinter?: boolean
  printerData?: any
  ordersState?: any
  notificationData?: any
}

const Content: React.FunctionComponent<ContentProps> = ({
  subscription,
  shipmentCards,
  shipmentHistoryTableRef,
  isCustomError,
  isCissPrinter,
  commonProps,
  printerData,
  ordersState,
  notificationData
}) => {
  const { t } = commonProps

  const activePaperSubscription = printerData?.activePaperSubscription

  const renderSubTitle = () => {
    let replacementText = t(
      'shipment.header.subtitle.nonCissPrinter',
      'Replacement ink cartridges'
    )
    let entityTypeText = t('shipment.header.subtitle.ink', 'ink')
    let shippingEntityText = t(
      'shipment.header.subtitle.cartridges',
      'cartridges'
    )

    if (isCissPrinter) {
      replacementText = t(
        'shipment.header.subtitle.cissPrinter',
        'Replacement ink bottles'
      )
      shippingEntityText = t(
        'shipment.header.subtitle.inkBottles',
        'ink bottles'
      )
    }

    if (activePaperSubscription) {
      replacementText = t('shipment.header.subtitle.supplies', 'Supplies')
      entityTypeText = t('shipment.header.subtitle.inkPaper', 'ink and paper')
      shippingEntityText = isCissPrinter
        ? t(
            'shipment.header.subtitle.cissPrinterPaper',
            'ink bottles and paper'
          )
        : t(
            'shipment.header.subtitle.nonCissPrinterPaper',
            'ink cartridges and paper'
          )
    }

    return t('shipment.header.subtitle.text', {
      replacementText,
      entityTypeText,
      shippingEntityText,
      defaultValue: `${replacementText} are shipped automatically, based on your ${entityTypeText}. We ship ${shippingEntityText} before you need them, not monthly.`
    })
  }

  const subTitleText = renderSubTitle()

  return (
    <>
      {subscription?.printer?.state !== SubscriptionStateEnum.CANCELED && (
        <Section data-testid="hpx-header-sub">
          <Subheader>
            <Subtitle>{subTitleText}</Subtitle>
          </Subheader>
        </Section>
      )}
      <NotificationSection>
        <MultipleNotification
          multiNotificationArray={notificationData?.notificationsList}
          screenPath="/ReactPaasDashboardShipmentTracking/ShipmentTracking/"
        />
      </NotificationSection>
      <Container>
        <RenderControlProvider>
          {subscription?.printer?.state !== SubscriptionStateEnum.CANCELED && (
            <>
              <PrintCards
                shipmentCards={shipmentCards}
                shipmentHistoryTableRef={shipmentHistoryTableRef}
                isCustomError={isCustomError}
                isCissPrinter={isCissPrinter}
                commonProps={commonProps}
                printerData={printerData}
              />
            </>
          )}
          <FaqPanel isCissPrinter={isCissPrinter}>
            <FaqCard commonProps={commonProps} isCissPrinter={isCissPrinter} />
            {!isCissPrinter && (
              <SupportText
                shipmentCards={shipmentCards}
                commonProps={commonProps}
              />
            )}
          </FaqPanel>
          <ShipmentHistory
            shipmentHistoryTableRef={shipmentHistoryTableRef}
            subscription={subscription}
            isCissPrinter={isCissPrinter}
            commonProps={commonProps}
            ordersState={ordersState}
          />
        </RenderControlProvider>
      </Container>
    </>
  )
}

export default Content
