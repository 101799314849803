import { StratusCASCommerceEnum } from '@/types'

const isCASSubscription = (activePrinterData, launchdarklyFlags) => {
  const isCASSubscription =
    activePrinterData?.printer?.commerce?.type ===
      StratusCASCommerceEnum.STRATUS_CAS_COMMERCE &&
    launchdarklyFlags?.pfEnableTargetState

  return isCASSubscription
}

export default isCASSubscription
