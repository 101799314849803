import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
import Accordion from '@veneer/core/dist/esm/scripts/accordion'
import { MarkdownText } from '@monetization/hpaip-ui-shared-components'

const { color, layout, typography } = tokens

export const AccordionHeaderTitle = styled.h4`
  && {
    font-size: ${typography.size2};
    line-height: ${typography.lineHeight2};
  }
`
export const FaqContainer = styled.section`
  && {
    grid-area: faq;
  }
`
export const AccordionText = styled(MarkdownText)`
  && {
    font-size: ${typography.size2};
    line-height: ${typography.lineHeight3};
  }
`

export const StyledAccordion = styled(Accordion)`
  && {
    /* height: fit-content; */
    background: ${color.white};
    /* padding: ${layout.size4}; */
    padding-left: ${layout.size5};

    div {
      line-height: ${typography.lineHeight2};
      margin-left: 0;
      padding-right: ${layout.size4};
      .vn-animated-height__container {
        padding: 0 12px;
        /* transition: all 0.5s ease-in-out !important;
        max-height: 0;

        &.vn-animated-height__container--entered {
          max-height: 500px !important;
        } */
      }
      .vn-animated-height__wrapper-inner > div {
        width: 100%;
      }
    }
  }
`
