const checkCancellationTriggered = (pendingData: any, printerEntityId: any) => {
  if (!pendingData) return false
  console.log(printerEntityId)
  const hasMatchingCancellations = pendingData?.contents?.some(
    (content: any) =>
      content?.type === 'cancellation' &&
      (content?.reason?.type === 'cancellationAfterTrialPeriod' ||
        content?.reason?.type === 'cancellationWithinTrialPeriod' ||
        content?.reason?.type === 'cancellation')
    //content?.entityId === printerEntityId
  )

  return hasMatchingCancellations // Return true or false
}

const checkReplacementTriggered = (pendingData: any, printerEntityId: any) => {
  if (!pendingData) return false

  const hasMatchingReplacements = pendingData?.contents?.some(
    (content: any) =>
      content?.type === 'cancellation' &&
      content?.reason?.type === 'warrantyRepairReplacement' &&
      content?.entityId === printerEntityId
  )

  return hasMatchingReplacements // Return true or false
}

export { checkCancellationTriggered, checkReplacementTriggered }
