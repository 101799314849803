const notificationConfig = {
  overview: {
    postcancellation: true,
    deliquency: true,
    preaarival: true,
    offline: true,
    instantInk: false,
    paperCancel: true,
    replacement: true
  },
  updatePlan: {
    preaarival: true,
    deliquency: true,
    offline: false,
    postcancellation: true,
    instantInk: false,
    paperCancel: true,
    replacement: true
  },
  Trackshipments: {
    postcancellation: true,
    preaarival: true,
    deliquency: true,
    offline: true,
    instantInk: false,
    replacement: true
  },
  orders: {
    deliquency: false,
    preaarival: false,
    offline: false,
    instantInk: false
  },
  ShippingBilling: {
    deliquency: true,
    preaarival: true,
    offline: true,
    instantInk: true
  },
  Subscriptions: {
    postcancellation: true,
    preaarival: true,
    deliquency: true,
    instantInk: false,
    paperCancel: true,
    replacement: true
  },
  PrintHistory: {
    postcancellation: true,
    deliquency: true,
    preaarival: false,
    offline: false,
    instantInk: false,
    paperCancel: false
  }
}

export default notificationConfig
