import {
  Order,
  OrderType,
  ProductType,
  CartridgeColor,
  OrderStatus
} from '@monetization/hpaip-notification-rules-react'

export type ShipmentData = {
  status: OrderStatus
  url: string
  tracking: string
  date: string
  productType: ProductType
  orderType: OrderType
  colorCode?: CartridgeColor
  quantity?: number
}

const shipmentStatus = ['shipped', 'cancelled', 'undelivered']

function getLastOrderStates(order: Order): ShipmentData[] {
  if (
    !shipmentStatus.includes(order.status) ||
    !Date.parse(order.timeStamp) ||
    order.productType === 'hardware'
  )
    return []

  const defaultOrderState = {
    status: order.status,
    url: order.trackingUrl || null,
    tracking: order.trackingNumber,
    productType: order.productType,
    orderType: order.orderType,
    date: order.timeStamp
  }

  const isWelcomeKitCartridge =
    order.productType === 'instantink' && order.orderType === 'welcomeKit'

  if (!Array.isArray(order.cartridges) || isWelcomeKitCartridge)
    return [defaultOrderState]

  return order.cartridges?.map(({ colorCode, quantity }) => ({
    ...defaultOrderState,
    colorCode,
    quantity
  }))
}

function getHistoryOrderStates(order: Order): ShipmentData[] {
  const historyShipmentStates = order.history?.filter(({ status }) =>
    shipmentStatus.includes(status)
  )

  if (!historyShipmentStates?.length || order.productType === 'hardware')
    return []

  const defaultOrderState = {
    url: order.trackingUrl || null,
    tracking: order.trackingNumber,
    productType: order.productType,
    orderType: order.orderType,
    status: order.status
  }

  const isWelcomeKitCartridge =
    order.productType === 'instantink' && order.orderType === 'welcomeKit'

  if (!Array.isArray(order.cartridges) || isWelcomeKitCartridge) {
    return historyShipmentStates
      .map(
        ({ timeStamp }) =>
          Date.parse(timeStamp) && {
            ...defaultOrderState,
            date: timeStamp
          }
      )
      .filter(Boolean)
  }

  return order.cartridges.reduce((states, { colorCode, quantity }) => {
    const statesByColor: ShipmentData[] = historyShipmentStates
      .map(
        ({ timeStamp, status }) =>
          Date.parse(timeStamp) && {
            ...defaultOrderState,
            colorCode,
            quantity,
            status,
            date: timeStamp
          }
      )
      .filter(Boolean)

    return states.concat(statesByColor)
  }, [])
}

export function getShipmentDataFromOrders(orders?: Order[]): ShipmentData[] {
  if (!orders) return []

  const parsedOrders: ShipmentData[] = orders.reduce((parsedOrders, order) => {
    const OrderShipmentHistory = []

    const lastOrderStates = getLastOrderStates(order)
    const historyOrderStates = getHistoryOrderStates(order)

    OrderShipmentHistory.push(...lastOrderStates, ...historyOrderStates)

    return parsedOrders.concat(OrderShipmentHistory)
  }, [])

  return parsedOrders
}
