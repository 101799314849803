import React, { Dispatch, SetStateAction } from 'react'
import { ShipmentTrackingPage } from '../ShipmentTracking/ShipmentTrackingPage'
import {
  useCommonNotification,
  useFlags
} from '@monetization/hpaip-notification-rules-react'
import {
  LoadingHandler,
  PrinterListOption
} from '@monetization/hpaip-ui-shared-components'
import ErrorContent from '../ShipmentTracking/components/ErrorContent'
import CommonProps from '../../types/commonProps'
import Analytics from '../Analytics'
import useMultipleSubscriptions from 'src/hooks/useMultipleSubscriptions'
import { PrinterListDiv } from './styles'

interface MainComponentProps {
  subscriptionId: string
  commonProps: CommonProps
  subscriptions?: any[]
  printerIndex?: number
  setPrinterIndex?: Dispatch<SetStateAction<number>>
}

const MainComponent = ({
  subscriptionId,
  commonProps,
  subscriptions,
  printerIndex,
  setPrinterIndex
}: MainComponentProps) => {
  const { enableBuyerRemorseCancellation, pfEnableNewReplacementFlow } =
    useFlags()
  const flagObject = {
    enableBuyerRemorseCancellation: enableBuyerRemorseCancellation,
    pfEnableNewReplacementFlow: pfEnableNewReplacementFlow
  }
  const data = useCommonNotification(
    'Trackshipments',
    subscriptionId,
    flagObject,
    null,
    null,
    commonProps?.navigation
  )
  const { printerList, loading: printerLoading } =
    useMultipleSubscriptions(subscriptions)

  const handlePrinterSelection = (e) => {
    localStorage.setItem('printerIndex', `${e?.id}`)
    setPrinterIndex(e?.id)
  }

  return (
    <>
      {data?.printerData?.root ? (
        <Analytics
          analytics={commonProps?.analytics}
          printerData={data?.printerData}
        >
          {printerList?.length > 0 && !printerLoading ? (
            <PrinterListDiv data-testid="printer-list">
              <PrinterListOption
                t={commonProps.t}
                PrinterDetails={printerList}
                defaultPrinter={printerIndex}
                onPrinterSelect={handlePrinterSelection}
                isOpen={true}
              />
            </PrinterListDiv>
          ) : (
            <></>
          )}
          <ShipmentTrackingPage commonProps={commonProps} data={data} />
        </Analytics>
      ) : (
        <LoadingHandler
          loading={data?.printerData?.loading || printerLoading}
          error={data?.printerData?.error}
          customError={
            <ErrorContent isCissPrinter={false} commonProps={commonProps} />
          }
          data-testid="loading"
        />
      )}
    </>
  )
}

export default MainComponent
