import React, { useEffect, useState } from 'react'
import { SubscriptionsStateClient } from '@monetization/hpaip-notification-rules-react'
import {
  HpxHeader,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import ErrorContent from '../ShipmentTracking/components/ErrorContent'
import MainComponent from '../MainComponent'
import CommonProps from '../../types/commonProps'
import { StyledHeader } from '../ShipmentTracking/styles'

interface InitialComponentProps {
  commonProps: CommonProps
}

const InitialComponent = ({ commonProps }: InitialComponentProps) => {
  const { authProvider, stack, t } = commonProps

  const [subscriptionId, setSubscriptionId] = useState<string>('')
  const [subscriptions, setSubscriptions] = useState([])
  const [printerIndex, setPrinterIndex] = useState<number>(
    Number(localStorage.getItem('printerIndex')) || 0
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const getAllSubscriptions = async () => {
    const subscriptionsClient = new SubscriptionsStateClient(
      authProvider,
      stack
    )
    setLoading(true)

    try {
      const subscriptions = await subscriptionsClient.getAllSubscriptions()
      setSubscriptions(subscriptions)
      setSubscriptionId(subscriptions?.[printerIndex]?.subscriptionId)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getAllSubscriptions()
  }, [])

  return (
    <>
      <StyledHeader>
        <HpxHeader
          data-testid={'load-printer-list'}
          title={t(
            'shipment.header.title',
            'Track HP All-In Plan supply shipments'
          )}
          showLogo={false}
        />
      </StyledHeader>
      {subscriptionId ? (
        <MainComponent
          commonProps={commonProps}
          subscriptionId={subscriptionId}
          data-testid="main-component"
          subscriptions={subscriptions}
          printerIndex={printerIndex}
          setPrinterIndex={setPrinterIndex}
        />
      ) : (
        <LoadingHandler
          loading={loading}
          error={error}
          customError={
            <ErrorContent
              isCissPrinter={false}
              commonProps={commonProps}
              data-testid="error-content"
            />
          }
          $position="absolute"
          data-testid="loading"
        />
      )}
    </>
  )
}

export default InitialComponent
