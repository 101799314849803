import {
  initializeNotificationsObject,
  processPostCancelNotification,
  processDeliquencyNotification,
  processPrearrivalNotification,
  processOfflineNotification,
  processInstantInkNotification,
  processPaperNotification,
  processReplacementNotification
} from '@/helpers/notificationHelper'
import { useState } from 'react'
import notificationConfig from '../../configs/notificationConfig'
import { useProductQuery } from '@/hooks/useProductQuery'
import { useGetActivePrinterBySubscription } from '../../hooks/useGetActivePrinterBySubscription'

export const useCommonNotification = (
  mfe,
  subscriptionId,
  launchdarklyFlags,
  navigateToBillingHandler,
  navigation,
  navigateToIIBillingHandler
) => {
  const [configData, setConfigData] = useState(notificationConfig)
  console.log(setConfigData, subscriptionId, launchdarklyFlags)
  const activePrinterData = useGetActivePrinterBySubscription(
    subscriptionId,
    launchdarklyFlags
  )

  const {
    printer,
    root,
    pendingData,
    isCancellationTriggered,
    isReplacementTriggered
  } = activePrinterData || {}

  const productData = useProductQuery(
    printer?.product?.value?.parentProductSku,
    mfe === 'ShippingBilling' // Prevents query from running
  )

  const allNotificationsObject = initializeNotificationsObject(
    activePrinterData,
    launchdarklyFlags,
    productData
  )

  if (mfe && configData[mfe]) {
    const mfeConfig = configData[mfe]

    processPostCancelNotification(
      mfeConfig,
      subscriptionId,
      root,
      printer,
      productData,
      navigation,
      allNotificationsObject,
      isCancellationTriggered,
      pendingData
    )

    processDeliquencyNotification(
      mfeConfig,
      mfe,
      activePrinterData,
      navigateToBillingHandler,
      navigation,
      allNotificationsObject
    )

    processPrearrivalNotification(
      mfeConfig,
      mfe,
      printer,
      launchdarklyFlags,
      navigation,
      subscriptionId,
      pendingData,
      allNotificationsObject
    )

    processOfflineNotification(mfeConfig, mfe, printer, allNotificationsObject)

    processInstantInkNotification(
      mfeConfig,
      navigateToIIBillingHandler,
      allNotificationsObject
    )

    processPaperNotification(
      mfeConfig,
      subscriptionId,
      navigation,
      allNotificationsObject,
      pendingData
    )

    processReplacementNotification(
      mfeConfig,
      launchdarklyFlags,
      printer,
      allNotificationsObject,
      isCancellationTriggered,
      isReplacementTriggered
    )
  }

  return allNotificationsObject
}
