import { useEffect, useState } from 'react'
import { getExpirationStatus } from '@/helpers/getExpirationStatus'
import { useInstantInk } from '@/hooks/useInstantInk'
import getAuthProvider from '@/helpers/getAuthProvider'
import getApplicationStack from '@/helpers/getApplicationStack'
import useGetText from '@/hooks/useGetText'
import { NotificationType, NotificationID } from '@/utils/common-utils'
import { InstantInkPaymentNotification } from '@/types/instantink'
import defaultLanguageJson from '@/assets/locale/en_US.json'

const GetAllInstantInkPaymentNotification = (
  navigateToBillingHandler,
  allNotificationsObject
) => {
  const getText = useGetText('instankInkPaymentNotification')
  const authProvider = getAuthProvider()
  const stack = getApplicationStack()
  const billingInfo = useInstantInk(authProvider, stack)
  const [expiringStatus, setExpiringStatus] = useState(null)
  allNotificationsObject.notificationApiInfo.instantInkBillingInfo = billingInfo
  allNotificationsObject.instantInkDeliquencyStatus = expiringStatus

  useEffect(() => {
    if (
      billingInfo == '404' ||
      !billingInfo?.creditCard?.displayExpirationDate
    ) {
      setExpiringStatus('none')
    } else if (billingInfo) {
      setExpiringStatus(
        getExpirationStatus(billingInfo?.creditCard?.displayExpirationDate)
          .notificationStatus
      )
    }
  }, [billingInfo])

  switch (expiringStatus) {
    case InstantInkPaymentNotification.ABOUT_TO_EXPIRED:
      return {
        title: getText('payment_about_to_expire.title', {
          defaultValue:
            defaultLanguageJson.instankInkPaymentNotification
              .payment_about_to_expire.title
        }),
        description: getText('payment_about_to_expire.description', {
          defaultValue:
            defaultLanguageJson.instankInkPaymentNotification
              .payment_about_to_expire.description
        }),
        notificationID: NotificationID.II_ABOUT_TO_EXPIRED,
        showCloseButton: false,
        notificationType: NotificationType.WARNING,
        callbackFunction: () => navigateToBillingHandler()
      }
    case InstantInkPaymentNotification.EXPIRED:
      return {
        title: getText('payment_expired.title', {
          defaultValue:
            defaultLanguageJson.instankInkPaymentNotification.payment_expired
              .title
        }),
        description: getText('payment_expired.description', {
          defaultValue:
            defaultLanguageJson.instankInkPaymentNotification.payment_expired
              .description
        }),
        notificationID: NotificationID.II_EXPIRED,
        showCloseButton: false,
        notificationType: NotificationType.NEGATIVE,
        callbackFunction: () => navigateToBillingHandler()
      }
    default:
      return null
  }
}

export default GetAllInstantInkPaymentNotification
