import { useState, useEffect } from 'react'
import { ReturnStateClient } from '@/services/ReturnStateClient'

export const useFetchReturnData = (tenantId, authProvider, stack) => {
  const [returnData, setReturnData] = useState(null)

  useEffect(() => {
    tenantId &&
      (async () => {
        const ReturnData = new ReturnStateClient(authProvider, stack)
        try {
          const response = await ReturnData.getReturnDetails(tenantId)
          const { data } = response
          if (Array.isArray(data)) {
            const standardReturn: any = data?.filter(
              (item) => item.returnType === 'standard'
            )
            standardReturn?.length > 0
              ? setReturnData(standardReturn)
              : setReturnData('404')
          }
        } catch (err) {
          setReturnData('404')
        }
      })()
  }, [tenantId, authProvider, stack])

  return Array.isArray(returnData) && returnData.length > 0
    ? returnData[0]
    : returnData
}
